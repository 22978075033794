<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-card-title>
            Email Address Management
          </v-card-title>
          <v-row justify="start">
            <v-col cols="4">
              <v-card-text>
                <v-form
                  v-model="validEmailSearch"
                  @submit.prevent="searchEmailsByEmailAddress"
                >
                  <v-text-field
                    v-model="emailAddress"
                    :rules="[rules.required, rules.email]"
                    class="mb-3"
                    maxlength="255"
                    label="Email Address"
                    hide-details="auto"
                    append-icon="mdi-magnify"
                    type="text"
                    filled
                  />
                  <v-col>
                    <v-btn
                      color="primary"
                      :disabled="!validEmailSearch"
                      @click="searchEmailsByEmailAddress"
                    >
                      search
                    </v-btn>
                  </v-col>
                </v-form>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                <v-form
                  v-model="validEmailNamesSearch"
                  @submit.prevent="getEmailsByParams"
                >
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="searchFirstName"
                        class="mb-3"
                        :rules="[rules.required]"
                        maxlength="30"
                        label="First Name"
                        hide-details="auto"
                        type="text"
                        filled
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="searchLastName"
                        class="mb-3"
                        :rules="[rules.required]"
                        maxlength="30"
                        label="Last Name"
                        hide-details="auto"
                        type="text"
                        filled
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="searchState"
                        class="mb-3"
                        :items="states"
                        item-text="stateName"
                        item-value="state"
                        label="State"
                        hide-details="auto"
                        :disabled="!areStateOptionsLoaded"
                        clearable
                        filled
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="searchZip"
                        class="mb-3"
                        maxlength="30"
                        label="ZIP"
                        hide-details="auto"
                        type="text"
                        clearable
                        filled
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="searchFileType"
                        class="mb-3"
                        :items="fileTypeOptions"
                        label="File Type"
                        hide-details="auto"
                        :disabled="!areFileTypeOptionsLoaded"
                        clearable
                        filled
                      />
                    </v-col>
                  </v-row>
                  <v-col>
                    <v-btn
                      color="primary"
                      :disabled="!validEmailNamesSearch"
                      @click="getEmailsByParams"
                    >
                      search
                    </v-btn>
                  </v-col>
                </v-form>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-row
            no-gutters
            justify="space-between"
          >
            <v-col
              sm="5"
              md="5"
            >
              <v-card-title class="ml-2">
                Search Results
              </v-card-title>
            </v-col>
          </v-row>
          <v-data-table
            :loading="isSearchingEmailByEmailAddress || isSearchingEmailByParams"
            item-key="rowID"
            class="mt-10"
            :headers="headers"
            :expanded="expanded"
            :items="emailSearchResults"
            disable-pagination
            hide-default-footer
            disable-filtering
            show-expand
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="addEmail"
                    color="primary"
                    class="mr-2"
                    name="addEmail"
                    v-bind="attrs"
                    v-on="on"
                    @click="addEmail(item)"
                  >
                    mdi-email-edit
                  </v-icon>
                </template>
                <span>Add New Email</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="unlinkEmail(item)"
                  >
                    mdi-link-variant-remove
                  </v-icon>
                </template>
                <span>Unlink Email</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="optOutEmail(item)"
                  >
                    mdi-alpha-x-circle-outline
                  </v-icon>
                </template>
                <span>OptOut Email</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-list>
                  <v-list-item class="d-inline-flex dropdown__item">
                    <v-list-item-content>
                      <v-list-item-title>Name</v-list-item-title>
                      <v-list-item-subtitle>{{ item.mailName }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="d-inline-flex dropdown__item">
                    <v-list-item-content>
                      <v-list-item-title>Specialty</v-list-item-title>
                      <v-list-item-subtitle>{{ item.specialty }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="d-inline-flex dropdown__item">
                    <v-list-item-content>
                      <v-list-item-title>Email Address</v-list-item-title>
                      <v-list-item-subtitle>{{ item.emailaddress }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-list>
                  <v-list-item class="d-inline-flex dropdown__item">
                    <v-list-item-content>
                      <v-list-item-title>File Type</v-list-item-title>
                      <v-list-item-subtitle>{{ item.filetype }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="d-inline-flex dropdown__item">
                    <v-list-item-content >
                      <v-list-item-title>ecode</v-list-item-title>
                      <v-list-item-subtitle>{{ item.ecode }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="d-inline-flex dropdown__item">
                    <v-list-item-content>
                      <v-list-item-title>mE11</v-list-item-title>
                      <v-list-item-subtitle>{{ item.mE11 }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="d-inline-flex dropdown__item">
                    <v-list-item-content>
                      <v-list-item-title>npi</v-list-item-title>
                      <v-list-item-subtitle>{{ item.npi }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-list>
                  <v-list-item v-if="item.fileType === 'AMA' || item.fileType === 'AOA' || item.fileType === 'ACCP'" class="d-inline-flex">
                    <v-list-item-content>
                      <v-list-item-title>Address</v-list-item-title>
                      <v-list-item-subtitle>
                        <div>
                          {{ item.mailAddress1 }}
                        </div>
                        <div>
                          {{ item.mailAddress2 }}
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else class="d-inline-flex dropdown__item">
                    <v-list-item-content>
                      <v-list-item-title>Address</v-list-item-title>
                      <v-list-item-subtitle>
                        <div>
                          {{ item.mailAddress2 }}
                        </div>
                        <div>
                          {{ item.mailAddress1 }}
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="d-inline-flex dropdown__item">
                    <v-list-item-content>
                      <v-list-item-title>City</v-list-item-title>
                      <v-list-item-subtitle>{{ item.mailCity }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="d-inline-flex dropdown__item">
                    <v-list-item-content>
                      <v-list-item-title>State</v-list-item-title>
                      <v-list-item-subtitle>{{ item.mailState }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="d-inline-flex dropdown__item">
                    <v-list-item-content>
                      <v-list-item-title>Zip</v-list-item-title>
                      <v-list-item-subtitle>{{ item.mailZip }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogAddEmail"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Add New Email
        </v-card-title>
        <v-card-text>
          <v-form
            ref="addNewEmailForm"
            v-model="validNewEmail"
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="currentEmailAddress"
                    disabled
                    label="Current Email"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="currentME11"
                    disabled
                    label="ID#"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="currentFileType"
                    disabled
                    label="Current FileType"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="newEmailAddress"
                    label="New Email"
                    hide-details="auto"
                    type="text"
                    :rules="newEmailAddressRules"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                text
                @click="cancelAddEmail"
              >
                Cancel
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                :disabled="!validNewEmail"
                :loading="isGettingEmailAdd"
                @click="confirmAddNewEmail(newEmailAddress)"
              >
                Add Email
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUnlinkEmail"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Unlink Email
        </v-card-title>
        <v-card-text>
          Click `Unlink {{ currentEmailAddress }}` to unlink {{ currentEmailAddress }} with {{ meNumber }}
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              @click="cancelUnlinkEmail"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              :loading="isGettingEmailUnlink"
              @click="confirmUnlinkEmail"
            >
              Unlink {{ currentEmailAddress }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogOptOut"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          OptOut Email
        </v-card-title>
        <v-card-text>
          Click `OptOut {{ currentEmailAddress }}` to complete Email OptOut
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              @click="cancelOptOutEmail"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              :loading="isGettingEmailOptOut"
              @click="confirmOptOutEmail"
            >
              OptOut {{ currentEmailAddress }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  emailMasterService,
  mailingAddressService,
  nowwService
} from '@/shared/services'

export default {
  name: 'EmailAddressManagement',
  data () {
    return {
      areStateOptionsLoaded: false,
      states: [],
      fileTypeOptions: [],
      areFileTypeOptionsLoaded: false,
      searchFirstName: '',
      searchLastName: '',
      searchState: '',
      searchZip: '',
      searchFileType: '',
      dialogAddEmail: false,
      currentME11: '',
      currentFileType: '',
      currentEmailAddress: '',
      meNumber: '',
      newEmailAddress: '',
      emailsThatAlreadyHaveMmsECode: [],
      newEmailAddressRules: [
        value => !!value || 'Required.',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        v => !this.emailsThatAlreadyHaveMmsECode.includes(v) || 'Email already has a MMS Ecode.'
      ],
      validNewEmail: false,
      dialogUnlinkEmail: false,
      dialogOptOut: false,
      validEmailSearch: false,
      validEmailNamesSearch: false,
      emailAddress: '',
      isSearchingEmailByEmailAddress: false,
      isSearchingEmailByParams: false,
      emailSearchResults: [],
      rules: {
        required: value => !!value || 'Required.',
        email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      },
      headers: [
        { text: 'File Type', value: 'filetype', align: 'start', sortable: true },
        { text: 'ECode', value: 'ecode', align: 'start', sortable: true },
        { text: 'ME #', value: 'mE11', align: 'start', sortable: true },
        { text: 'NPI #', value: 'npi', align: 'start', sortable: true },
        { text: 'Name', value: 'emailaddress', align: 'start', sortable: true },
        { text: 'Mail Name', value: 'mailName', align: 'start', sortable: true },
        { text: 'Actions', value: 'actions', align: 'start', sortable: true },
        { text: '', value: 'data-table-expand' },
      ],
      expanded: [],
      isGettingEmailOptOut: false,
      isGettingEmailUnlink: false,
      isGettingEmailAdd: false
    }
  },
  computed: {
    ...mapGetters('auth', ['getTenantCrmId'])
  },
  mounted () {
    this.loadFileTypes(
      this.$store.getters['auth/getTenantCrmId']()
    )
    this.getStates()
  },
  methods: {
    validateAddNewEmailForm () {
      this.$refs.addNewEmailForm.validate()
    },
    addEmail (item) {
      this.currentME11 = item.mE11
      this.currentFileType = item.filetype
      this.currentEmailAddress = item.emailaddress
      this.newEmailAddress = ''
      this.dialogAddEmail = true
    },
    async confirmAddNewEmail (newEmailAddress) {
      const res = await this.emailAddNew(this.currentEmailAddress, newEmailAddress, this.currentME11, this.currentFileType)
    },
    cancelAddEmail () {
      this.dialogAddEmail = false
    },
    unlinkEmail (item) {
      this.dialogUnlinkEmail = true
      this.currentEmailAddress = item.emailaddress
      this.meNumber = item.mE11
    },
    async confirmUnlinkEmail () {
      await this.emailUnlink(this.currentEmailAddress, this.meNumber)
      this.currentEmailAddress = ''
      this.meNumber = ''
      this.dialogUnlinkEmail = false
    },
    cancelUnlinkEmail () {
      this.currentEmailAddress = ''
      this.dialogUnlinkEmail = false
    },
    optOutEmail (item) {
      this.dialogOptOut = true
      this.currentEmailAddress = item.emailaddress
    },
    async confirmOptOutEmail () {
      await this.emailOptOut(this.currentEmailAddress)
      this.currentEmailAddress = ''
      this.dialogOptOut = false
    },
    cancelOptOutEmail () {
      this.currentEmailAddress = ''
      this.dialogOptOut = false
    },
    async searchEmails (newEmail) {
      this.isSearchingEmailByEmailAddress = true
      const res = await emailMasterService.getEmailsByEmailAddress(newEmail)
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      } else {
        this.emailSearchResults = res
      }

      this.isSearchingEmailByEmailAddress = false
    },
    async searchEmailsByEmailAddress () {
      this.isSearchingEmailByEmailAddress = true
      const res = await emailMasterService.getEmailsByEmailAddress(this.emailAddress)
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      } else {
        this.emailsThatAlreadyHaveMmsECode = []
        this.emailSearchResults = res
      }
      this.isSearchingEmailByEmailAddress = false
    },
    async getEmailsByParams () {
      this.isSearchingEmailByParams = true
      const res = await emailMasterService.getEmailsByParams({
        firstName: this.searchFirstName,
        lastName: this.searchLastName,
        state: this.searchState,
        zip: this.searchZip,
        fileType: this.searchFileType
      })

      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      } else {
        this.emailsThatAlreadyHaveMmsECode = []
        this.emailSearchResults = res
      }

      this.isSearchingEmailByParams = false
    },
    async emailAddNew (originalEmail, newEmail, meNumber, fileType) {
      this.isGettingEmailAdd = true

      const res = await emailMasterService.emailAddNew(originalEmail, newEmail, meNumber, fileType)
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      }

      if (res && res.result === 'EMail already exists under MMS Ecode') {
        this.$store.commit('setGlobalSnackbar', {
          message: 'Email already exists under MMS Ecode',
          color: 'error'
        })
        this.emailsThatAlreadyHaveMmsECode.push(newEmail)
        this.validateAddNewEmailForm()
        this.isGettingEmailAdd = false
      } else {
        await this.searchEmails(newEmail)
        this.isGettingEmailAdd = false
        this.dialogAddEmail = false
      }
    },
    async emailOptOut (email) {
      this.isGettingEmailOptOut = true

      const res = await emailMasterService.emailOptOut(this.currentEmailAddress)

      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      }

      await this.searchEmailsByEmailAddress()

      this.isGettingEmailOptOut = false
    },
    async emailUnlink (email, meNumber) {
      this.isGettingEmailUnlink = true

      const res = await emailMasterService.emailUnlink(email, meNumber)

      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      }

      await this.searchEmailsByEmailAddress()

      this.isGettingEmailUnlink = false
    },
    async loadFileTypes (tenantId) {
      await nowwService
        .getFileTypes(tenantId)
        .then(res => {
          if (res.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: res.errorResponse,
              color: 'error'
            })
            this.fileTypeOptions = []
          } else {
            this.fileTypeOptions = res.map(x => x.fileTypeName)
            this.areFileTypeOptionsLoaded = true
          }
        })
    },
    async getStates () {
      await mailingAddressService.getStates().then((res) => {
        if (res.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: res.errorResponse,
            color: 'error'
          })
          this.states = []
        } else {
          this.states = res
        }
        this.areStateOptionsLoaded = true
      })
    }
  }
}
</script>

<style>
  .dropdown__item {
    min-width: 300px;
    max-width: 300px;
  }
</style>
